.green-background {
  /* background: linear-gradient(to bottom, #2d6a25, 95%, white); */
  background: #b7cf99;
}
.orange-background {
  /* background: linear-gradient(to top, #2d6a25, 40%, white); */
  background: #f7892e;
}
.top-header-background {
  background: linear-gradient(white, #2d6a25, white);
}
.blue-background {
  background: #4faaa7;
}
.red-background {
  background: #c6460f;
}
.yellow-background {
  background: #eac761;
}
.zoom {
  transition: 0.5s;
}
.zoom:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.super-zoom {
  transition: 0.5s;
}
.super-zoom:hover {
  transform: scale(1.2);
}
.mega-zoom {
  transition: 0.5s;
}
.mega-zoom:hover {
  transform: scale(1.3);
}
.card-shadow {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.main-text {
  position: absolute;
  top: 20%;
  width: 100%;
  color: black;
}
.sub-text {
  font-size: 150px;
}
@media (max-width: 575px) {
  .carousel-item {
    height: 500px;
  }
  .carousel img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 400px;
  }
  .main-card {
    height: 400px;
  }
  .sub-text {
    font-size: 75px;
  }
}
@media (min-width: 576px) {
  .carousel-item {
    height: 375px;
  }
  .carousel img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 500px;
  }
  .sub-text {
    font-size: 100px;
  }
}
@media (min-width: 768px) {
  .carousel-item {
    height: 250px;
  }
  .carousel img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 600px;
  }
  .main-card {
    height: 500px;
  }
  .sub-text {
    font-size: 150px;
  }
}
@media (min-width: 992px) {
  .carousel-item {
    height: 200px;
  }
  .carousel img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 500px;
  }
}
@media (min-width: 1200px) {
  .carousel-item {
    height: 200px;
  }
  .carousel img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 600px;
  }
}
